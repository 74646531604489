import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm/ContactForm"
import Font from "../components/styles/FontStyle"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 0, 3),
  },
}))

const ContactPage = () => {
  const {
    prismicBasicInfo: {
      data: { main_color_two, main_color_three },
    },
  } = useStaticQuery(graphql`
    query contactPage {
      prismicBasicInfo {
        data {
          main_color_two
          main_color_three
        }
      }
    }
  `)

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SEO
        title="Neem contact op!"
        description="Neem hier vrijblijvend contact op met MD Design \\& Development voor uw Grafic design, webontwikkeling of app"
      />
      <ContactForm
        maxWidth={1150}
        button={{
          text: main_color_three,
          background: main_color_two,
          font: Font,
        }}
      />
    </div>
  )
}

export default ContactPage
